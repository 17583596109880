<template>
    <div>

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <router-link
                    :to="{name:'CrewMedicalDocument'}"
                    :active-class="'active bg-light-gray font-weight-bold'"
                    class="nav-link text-dark">
              Medical
            </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name:'CrewHealthChecklist'}"
            :active-class="'active bg-light-gray font-weight-bold'"
            class="nav-link text-dark">
            Health Checklist
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name:'CrewPcr'}"
            :active-class="'active bg-light-gray font-weight-bold'"
            class="nav-link text-dark">
            Rapid/PCR Test
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name:'CrewVaccine'}"
            :active-class="'active bg-light-gray font-weight-bold'"
            class="nav-link text-dark">
            Vaccine
          </router-link>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "MedicalNav"
}
</script>

<style scoped>

ul > li,
ul > li > button {
  color: white;
  margin: 0 1px;
}
ul > li > button:focus,
ul > li > button:active {
  outline:none;
}

li>button.active, .nav-tabs .nav-link.active {
  background: white !important;
  font-weight: bold;
  color: black ;
}
.bg-online-exam-red {
  background-color: #c53030;
}
.bg-light-gray {
  background-color: lightgray;
}

ul>li>a.nav-link.active {
  font-weight: bold;
  background-color: lightgray !important;
  border-color: lightgray;
  color: #c53030 !important;
}

</style>
