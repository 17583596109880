<template>
    <div>
      <div class="row">
        <div class="col-9">
            <h5 class="pl-1 font-weight-bold my-2">Medicals</h5>
        </div>
        <div class="col-3">
          <select name="medicalDocuments" id="medicalDocuments" class="form-control form-control-sm" v-model="medicalType">
            <option :value="null">SELECT ALL</option>
            <option :value="medicalDoc.id" v-for="medicalDoc in medicalKeys">{{medicalDoc.name}}</option>
          </select>
        </div>
        <div class="col-12">
          <medical-nav></medical-nav>
        </div>
        <div class="col-12">
          <table class="table table-sm table-bordered small">
              <thead>
                <tr>
                  <td class="align-middle text-center font-weight-bold">Medical Type</td>
                  <td class="align-middle text-center font-weight-bold">Clinic</td>
                  <td class="align-middle text-center font-weight-bold">Fitness Date</td>
                  <td class="align-middle text-center font-weight-bold">Diagnosis</td>
                  <td class="align-middle text-center font-weight-bold">HBA Test</td>
                  <td class="align-middle text-center font-weight-bold">Recommendation</td>
                  <td class="align-middle text-center font-weight-bold">Remarks</td>
                  <td class="align-middle text-center font-weight-bold">Issued Date</td>
                  <td class="align-middle text-center font-weight-bold">Expiry Date</td>
                  <td class="align-middle text-center font-weight-bold">Action</td>
                </tr>
              </thead>
              <tbody>
                  <template v-if="crewDocuments">
                    <tr v-for="medical in filteredMedicals">
                      <td>{{medical.type}}</td>
                      <td>{{medical.clinic ? medical.clinic : medical.other_clinic}}</td>
                      <td class="text-center">{{formatEliteDate(medical.fitness_date)}}</td>
                      <td>{{medical.diagnosis}}</td>
                      <td class="text-center font-weight-bold">{{medical.hba_test ? 'YES' : 'NO'}}</td>
                      <td>{{medical.recommendation}}</td>
                      <td>{{medical.remarks}}</td>
                      <td>{{formatEliteDate(medical.issued_date)}}</td>
                      <td>{{formatEliteDate(medical.expiry_date)}}</td>
                      <td>
                        <button class="btn btn-secondary btn-xs" data-toggle="modal" data-target="#crewMedical" @click="viewCrewMedical(medical)">View</button>
                      </td>
                    </tr>
                  </template>
              </tbody>
          </table>
        </div>
      </div>
      <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewMedical" tabindex="-1" aria-labelledby="crewDocument" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style="height:auto">
            <div class="modal-header">
              <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW MEDICAL RECORD</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-5">
                  <table class="w-100">
                    <tr>
                      <td>Medical Type:</td>
                      <td class="font-weight-bold">{{medical.type}}</td>
                    </tr>
                    <tr>
                      <td>Medical Clinic:</td>
                      <td class="font-weight-bold">{{medical.clinic ? medical.clinic : medical.other_clinic}}</td>
                    </tr>
                    <tr>
                      <td>Fitness Date:</td>
                      <td class="font-weight-bold">{{formatEliteDate(medical.fitness_date)}}</td>
                    </tr>
                    <tr>
                      <td>Expiry Date:</td>
                      <td class="font-weight-bold">{{formatEliteDate(medical.expiry_date)}}</td>
                    </tr>
                    <tr>
                      <td>Diagnosis:</td>
                      <td class="font-weight-bold">{{medical.diagnosis}}</td>
                    </tr>
                    <tr>
                      <td>Recommendation:</td>
                      <td class="font-weight-bold">{{medical.recommendation}}</td>
                    </tr>
                    <tr>
                      <td>HBA Test:</td>
                      <td class="font-weight-bold">{{medical.hba_test ? 'YES' : 'NO'}}</td>
                    </tr>
                    <tr>
                      <td>Remarks:</td>
                      <td class="font-weight-bold">{{medical.remarks}}</td>
                    </tr>
                  </table>
                  <hr>
                </div>
                <div class="col-7" style="height: 77vh">
                  <template v-if="medicalFile">
                    <iframe style="height: inherit; width: 100%" :src="medicalFile"></iframe>
                  </template>
                  <div v-else class="alert alert-danger">
                    NO FILE FOUND
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MedicalNav from "@/components/crew/document/medical/MedicalNav";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "Medical",
  components:{MedicalNav},
  data(){
    return {
      medicalType:null,
      medical:{},
      medicalFile:''
    }
  },
  computed:{
    ...mapGetters(['medicalKeys','crewDocuments']),
    filteredMedicals(){
      return this.medicalType ?  this.crewDocuments.filter(doc=>doc.document_id === this.medicalType) : this.crewDocuments
    }
  },
  methods:{
    ...mapActions(['getMedicalKeys','getCrewMedicals']),
    formatEliteDate(date){
      return DateService.dateEliteFormat(date);
    },
    async viewCrewMedical(medical){
      this.medicalFile=false;
      this.medical=Object.assign({},medical);
      const crewDocumentFile= await CrewService.getCrewMedicalFileFromCms({crew_medical_id: medical.id});
      this.medicalFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
    },
  },
  async created() {
    await this.getMedicalKeys({});
    const params= {
      crew_id : this.$route.params.id,
    }

    await this.getCrewMedicals(params);
  }
}
</script>

<style scoped>

</style>
